<template>
    <ul :class="variant">
        <li v-for="string in list" :key="string">
            <span v-html="string"/>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "UUlList",
        props: {
            variant: {
                type: String,
                default: 'circle'
            },
            list: {
                type: Array,
                default() {
                    return [
                        '1', '2'
                    ]
                },
            },
        }
    }
</script>

<style scoped lang="scss">

    ul {
        counter-reset: item;
        list-style: none;
        padding: 0;

        &.decimal {
            li {
                position: relative;
                margin: 0 0 45px 0;
                @include _lg {
                    margin: 0 0 20px 0;
                }

                span {
                    display: block;
                    padding: 0 0 0 80px;
                    @include _lg {
                        padding: 70px 0 0 0;
                    }
                }

                &:before {
                    content: counter(item);
                    counter-increment: item;

                    background: $site-color-light;
                    border-radius: 50%;

                    color: $site-color;
                    font-size: $font-size-h4;
                    line-height: $font-size-h4;
                    font-weight: bold;
                    text-align: center;
                    display: block;
                    width: 50px;
                    height: 50px;

                    position: absolute;
                    top: calc(50% - 25px);
                    left: 0;

                    z-index: 2;
                    @include _lg {
                        width: 42px;
                        height: 42px;
                        top: 0;
                    }
                }

                &:after {
                    content: '';
                    width: 62px;
                    height: 10px;
                    position: absolute;
                    left: 11px;
                    top: calc(50% - 5px);
                    background: url("/img/icon/ul-decimal.svg") no-repeat center;

                    z-index: 1;
                    @include _lg {
                        transform: rotate(90deg);
                        left: -10px;
                        top: 30px;
                    }
                }

                @include _lg {
                    &:nth-child(even) {
                        &:before {
                            left: calc(50% - 21px);
                        }

                        &:after {
                            left: calc(50% - 30px);
                        }
                    }
                }
            }
        }

        &.circle {
            li {
                position: relative;
                margin: 0 0 20px 0;
                @include _lg {
                    margin: 0 0 10px 0;
                }

                span {
                    display: block;
                    padding: 0 0 0 25px;
                    @include _lg {
                        font-size: $font-size-small;
                        padding: 0 0 0 10px;
                    }
                }

                &:before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: $site-color;
                    border-radius: 50%;
                    position: absolute;
                    top: 7px;
                    left: 0;
                    @include _lg {
                        width: 5px;
                        height: 5px;
                    }
                }
            }
        }
    }
</style>